html {
  font-size: 8px;
}

@media (min-width: 320px) and (max-width: 1024px) {
  html {
    font-size: 10px;
  }
}

body {
  font-family: 'Inter', 'Spoqa Han Sans Neo';
  margin: 0;
}
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);
@font-face {
  font-family: 'Inter';
  font-weight: 100;
  font-style: normal;
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
  src: url('./assets/fonts/Inter-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 300;
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
  src: url('./assets/fonts/Inter-Bold.woff2') format('woff2');
}


@font-face {
  font-family: 'Inter';
  font-weight: 200;
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
  src: url('./assets/fonts/Inter-SemiBold.otf') format('otf');
}
@font-face {
  font-family: 'Inter';
  font-weight: 500;
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
  src: url('./assets/fonts/Inter-ExtraBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 100;
  src: url('./assets/fonts/SpoqaHanSansNeo-Regular.otf') format('otf');
}

@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 300;
  src: url('./assets/fonts/SpoqaHanSansNeo-Medium.otf') format('otf');
}

@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 500;
  src: url('./assets/fonts/SpoqaHanSansNeo-Bold.otf') format('otf');
}
